import styles from './GameNavigation.module.scss';
import cn from 'classnames';

type TProps = {
  current: number;
  total: number;
};

export const GameNavigation = ({ total, current }: TProps) => {
  return (
    <div className={styles.root}>
      {Array.from({ length: total }, (_, i) => i + 1).map((item, index) => {
        return (
          <span
            key={`nav_key_${index}_${item}`}
            className={cn(styles.item, {
              [styles.item_active_line]: current >= index + 1,
              [styles.item_active]: current >= index,
            })}
          >
            {item}
          </span>
        );
      })}
    </div>
  );
};
