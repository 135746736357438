import styles from './Question.module.scss';
import { TAnswer } from '../../types';
import cn from 'classnames';
import { Button } from '@/src/components/Button';
import { useGameContext } from '../../GameContext';
import { noop } from '@/src/utils';
import { useDispatch } from 'react-redux';
import { addAnswer } from '@/src/store/slices/answersSlice';
import { GAME_DATA } from '../../data';
import { usePageContext } from '@/src/components/App/PageContext';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'usehooks-ts';
import { sendEventToCounters } from '@/src/counterEvents';

type TProps = {
  question: TAnswer[];
};

export const Question = ({ question }: TProps) => {
  const [answer1, answer2] = question;

  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const gameContext = useGameContext();
  const setCurrentStep = gameContext?.setCurrentStep || noop;
  const currentStep = gameContext?.currentStep || 0;
  const setSelectedAnswer = gameContext?.setSelectedAnswer || noop;
  const selectedAnswer = gameContext?.selectedAnswer || null;

  const dispatch = useDispatch();

  const isMediaQueryLess1200 = useMediaQuery('(max-width: 1200px)');
  const isMediaQueryLess800 = useMediaQuery('(max-width: 800px)');

  const {
    id: id1,
    name: name1,
    image: image1,
    image_mobile: image_mobile1,
    description: description1,
  } = answer1;
  const {
    id: id2,
    name: name2,
    image: image2,
    image_mobile: image_mobile2,
    description: description2,
  } = answer2;

  const TextAnswer1 = () => {
    return (
      <div
        className={cn(styles.text, {
          [styles.text_open]: selectedAnswer === -1,
        })}
      >
        <div className={styles.text_box}>
          <motion.span
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            key={name1}
            className={styles.title}
          >
            {name1}
          </motion.span>
          <div className={styles.text_hidden}>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description1 }}
            />
            <Button
              text="Далее"
              color="white"
              // size="small"
              onClick={() => handleNext(id1)}
            />
          </div>
        </div>
      </div>
    );
  };

  const TextAnswer2 = () => {
    return (
      <div
        className={cn(styles.text, {
          [styles.text_open]: selectedAnswer === 1,
        })}
      >
        <div className={styles.text_box}>
          <motion.span
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            key={name2}
            className={styles.title}
          >
            {name2}
          </motion.span>
          <div className={styles.text_hidden}>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description2 }}
            />
            <Button
              text="Далее"
              color="white"
              // size="small"
              onClick={() => handleNext(id2)}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleNext = (id: string) => {
    dispatch(addAnswer(id));

    sendEventToCounters({
      action: 'question' + (currentStep + 1),
      label: 'futher',
    });

    if (currentStep === GAME_DATA.length - 1) {
      changePage('final');
      return;
    }

    setCurrentStep((prev) => prev + 1);
    setSelectedAnswer(null);
  };

  return (
    <>
      <div className={styles.row}>
        <div
          className={cn(styles.item, styles.item_left, {
            [styles.item_disabled]: selectedAnswer === 1,
          })}
        >
          <div className={styles.image}>
            <motion.img
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              src={isMediaQueryLess1200 ? image_mobile1 : image1}
              key={image1}
              width={610}
              height={377}
            />
          </div>

          {!isMediaQueryLess800 ? (
            <div
              className={cn(styles.text, {
                [styles.text_open]: selectedAnswer === -1,
              })}
            >
              <div className={styles.text_box}>
                <motion.span
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  key={name1}
                  className={styles.title}
                >
                  {name1}
                </motion.span>
                <div className={styles.text_hidden}>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description1 }}
                  />
                  <Button
                    text="Далее"
                    color="white"
                    // size="small"
                    onClick={() => handleNext(id1)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <motion.span
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              key={name1}
              className={styles.title}
            >
              {name1}
            </motion.span>
          )}
        </div>

        <div
          className={cn(styles.item, styles.item_right, {
            [styles.item_disabled]: selectedAnswer === -1,
          })}
        >
          <div className={styles.image}>
            <motion.img
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              src={isMediaQueryLess1200 ? image_mobile2 : image2}
              key={image2}
              width={610}
              height={377}
            />
          </div>

          {!isMediaQueryLess800 ? (
            <div
              className={cn(styles.text, {
                [styles.text_open]: selectedAnswer === 1,
              })}
            >
              <div className={styles.text_box}>
                <motion.span
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  key={name2}
                  className={styles.title}
                >
                  {name2}
                </motion.span>
                <div className={styles.text_hidden}>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description2 }}
                  />
                  <Button
                    text="Далее"
                    color="white"
                    // size="small"
                    onClick={() => handleNext(id2)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <motion.span
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              key={name2}
              className={styles.title}
            >
              {name2}
            </motion.span>
          )}
        </div>
      </div>

      {isMediaQueryLess800 && (
        <div className={styles.mobile_text}>
          <TextAnswer1 />
          <TextAnswer2 />
        </div>
      )}
    </>
  );
};
