import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type TGameContext = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  selectedAnswer: -1 | 1 | null;
  setSelectedAnswer: Dispatch<SetStateAction<-1 | 1 | null>>;
};

export const GameContext = createContext<TGameContext | null>(null);

export const useGameContext = () => useContext(GameContext);
