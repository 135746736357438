import { sendEventToCounters } from '@/src/counterEvents';
import styles from './Footer.module.scss';
import hh_logo from './images/hh_logo.svg';

export const Footer = () => {
  const sendAnalityc = (label: string) => {
    sendEventToCounters({ action: 'footer', label });
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footer_container}>
        <div className={styles.copyright}>
          <p className={styles.brand}>© Самолет 2024</p>
          <div className={styles.hh}>
            <img className={styles.hh_logo} src={hh_logo} alt="Логотип HH.ru" />
            <p className={styles.hh_text}>Бренд-центр</p>
          </div>
        </div>
        <ul className={styles.links}>
          <li className={styles.link_item}>
            <a
              className={styles.link}
              href="https://career.samolet.ru/?utm_source=samolet_hh&utm_medium=referral&utm_campaign=special_project_career"
              target="_blank"
              rel="noreferrer"
              onClick={() => sendAnalityc('career')}
            >
              Карьера в Самолет
            </a>
          </li>
          <li className={styles.link_item}>
            <a
              className={styles.link}
              href="https://hh.ru/employer/1102601?utm_source=samolet_hh&utm_medium=referral&utm_campaign=special_project_vacancy"
              target="_blank"
              rel="noreferrer"
              onClick={() => sendAnalityc('vacancies')}
            >
              Вакансии
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
