import cn from 'classnames';
import styles from './Button.module.scss';

type TProps = {
  onClick?: () => void;
  color?: 'blue' | 'white';
  text: string;
  size?: 'medium' | 'small';
  type?: 'button' | 'link';
  href?: string;
};

export const Button = ({
  text,
  color = 'white',
  onClick,
  type = 'button',
  href,
  size = 'medium',
}: TProps) => {
  const renderButton = () => {
    if (type === 'button') {
      return (
        <button
          className={cn(styles.root, {
            [styles.white]: color && color === 'white',
            [styles.blue]: color && color === 'blue',
            [styles.small]: size && size === 'small',
          })}
          onClick={onClick}
        >
          <span>{text}</span>
        </button>
      );
    }

    if (type === 'link') {
      return (
        <a
          className={cn(styles.root, {
            [styles.white]: color && color === 'white',
            [styles.blue]: color && color === 'blue',
            [styles.small]: size && size === 'small',
          })}
          href={href}
          target="_blank"
          rel="noreferrer"
          onClick={onClick}
        >
          <span>{text}</span>
        </a>
      );
    }
  };

  return renderButton();
};
