import { GAME_RESULTS } from '../Game/data';

export const getResultText = (results: string[]) => {
  const text: string[] = [];

  results.map((result: string, index) => {
    const arrResults = GAME_RESULTS[index][result];

    text.push(arrResults[Math.floor(Math.random() * arrResults.length)]);
  });

  return text.length === GAME_RESULTS.length
    ? `Ты ${text[0]}, ${text[1]}, ${text[2]}, ${text[3]} и ${text[4]}.`
    : 'Произошла ошибка при вычислении результата...';
};
