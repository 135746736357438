import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './GameSelector.module.scss';
import cn from 'classnames';
import planetSrc from './images/planet.png';
import dividerSrc from './images/divider.png';
import { useGameContext } from '../../GameContext';
import { noop } from '@/src/utils';
import { AnimateFade } from '@/src/components/AnimateFade';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'usehooks-ts';
import { sendEventToCounters } from '@/src/counterEvents';

const MIN_RANGE_VALUE = -10;
const MAX_RANGE_VALUE = 10;

export const GameSelector = () => {
  const gameContext = useGameContext();
  const setSelectedAnswer = gameContext?.setSelectedAnswer || noop;
  const selectedAnswer = gameContext?.selectedAnswer || null;
  const currentStep = gameContext?.currentStep || 0;

  const [inputRangeValue, setInputRangeValue] = useState(0);
  const rangeWrapperRef = useRef<HTMLDivElement | null>(null);
  const rangeRef = useRef<HTMLInputElement | null>(null);

  const isMediaQueryLess800 = useMediaQuery('(max-width: 800px)');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setInputRangeValue(value);
  };

  const sendAnalityc = () => {
    sendEventToCounters({
      action: 'question' + (currentStep + 1),
      label: 'any',
    });
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      if (rangeRef.current) {
        if (inputRangeValue < 0) {
          setInputRangeValue(MIN_RANGE_VALUE);
          setSelectedAnswer(-1);
          sendAnalityc();
        }

        if (inputRangeValue > 0) {
          setInputRangeValue(MAX_RANGE_VALUE);
          setSelectedAnswer(1);
          sendAnalityc();
        }
      }
    }, 1);
  };

  useEffect(() => {
    if (selectedAnswer === null) {
      setInputRangeValue(0);
    }
  }, [selectedAnswer]);

  return (
    <div className={styles.root}>
      <motion.img
        initial={{ opacity: 0, y: isMediaQueryLess800 ? 10 : 40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: isMediaQueryLess800 ? 1.2 : 1, duration: 0.5 }}
        className={styles.divider}
        src={dividerSrc}
      />
      <AnimateFade delay={0.8}>
        <div className={styles.planet}>
          <img src={planetSrc} alt="" />
        </div>
      </AnimateFade>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
        className={styles.range_wrapper}
        ref={rangeWrapperRef}
      >
        <div
          className={cn(styles.range_box, {
            [styles.range_selected]: selectedAnswer !== null,
          })}
        >
          <input
            type="range"
            min={MIN_RANGE_VALUE}
            max={MAX_RANGE_VALUE}
            value={inputRangeValue}
            onChange={handleChange}
            onMouseUp={handleMouseUp}
            onTouchEnd={handleMouseUp}
            className={styles.range}
            ref={rangeRef}
          />
          {rangeWrapperRef.current ? (
            <>
              <span
                className={styles.range_line_left}
                style={{
                  width:
                    inputRangeValue < 0
                      ? (rangeWrapperRef.current?.offsetWidth /
                          MAX_RANGE_VALUE /
                          2 -
                          3) *
                          Math.abs(inputRangeValue + 1) +
                        24 +
                        (isMediaQueryLess800 ? 10 : 0)
                      : 0,
                }}
              />
              <span
                className={styles.range_line_right}
                style={{
                  width:
                    inputRangeValue > 0
                      ? (rangeWrapperRef.current?.offsetWidth /
                          MAX_RANGE_VALUE /
                          2 -
                          3) *
                          (inputRangeValue - 1) +
                        24 +
                        (isMediaQueryLess800 ? 10 : 0)
                      : 0,
                }}
              />
            </>
          ) : null}
        </div>
      </motion.div>
    </div>
  );
};
