import styles from './GameTitle.module.scss';

export const GameTitle = () => {
  return (
    <div className={styles.root}>
      <p>
        Покажи, <span>какой ты!</span> <br /> Тяни бегунок
      </p>
      <div className={styles.star}>
        <svg viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M27.9772 2.00252C25.4456 1.74291 19.4739 21.6032 17.721 21.6032C15.9682 21.6032 2.5315 19.786 2.0122 21.6032C1.4929 23.4205 17.721 35.1031 17.721 36.7905C17.721 38.478 13.9561 54.7038 15.0596 55.9369C16.1631 57.1701 26.7439 39.9059 27.9772 40.2304C29.2106 40.5549 45.179 56.8456 46.1527 55.9369C47.1264 55.0283 37.3246 38.6078 37.3246 36.7905C37.3246 34.9732 54.9159 23.0311 55.9545 21.6032C56.9931 20.1754 39.9211 21.6032 37.3246 21.6032C34.7281 21.6032 30.5088 2.26214 27.9772 2.00252Z"
            stroke="#007BFB"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};
