import cn from 'classnames';
import styles from './HomePlanet.module.scss';
import planetSrc from './images/planet.png';
import textSrc from './images/text.png';
import elem1Src from './images/elem1.svg';
import elem2Src from './images/elem2.svg';
import elem3Src from './images/elem3.svg';
import elem4Src from './images/elem4.svg';
import elem5Src from './images/elem5.svg';
import elem6Src from './images/elem6.svg';
import { motion } from 'framer-motion';

export const HomePlanet = () => {
  return (
    <div className={styles.root}>
      <motion.div
        initial={{ opacity: 0, scale: 0.5, rotate: 90 }}
        animate={{ opacity: 1, scale: 1, rotate: 0 }}
        transition={{ duration: 0.5 }}
        className={styles.planet}
      >
        <img src={planetSrc} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        className={styles.text}
      >
        <img src={textSrc} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className={cn(styles.planet_elem, styles.planet_elem_1)}
      >
        <img src={elem1Src} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={cn(styles.planet_elem, styles.planet_elem_2)}
      >
        <img src={elem2Src} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className={cn(styles.planet_elem, styles.planet_elem_3)}
      >
        <img src={elem3Src} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20, x: -50 }}
        animate={{ opacity: 1, y: 0, x: 0 }}
        transition={{ delay: 0.8, duration: 0.5 }}
        className={cn(styles.planet_elem, styles.planet_elem_4)}
      >
        <img src={elem4Src} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.5 }}
        className={cn(styles.planet_elem, styles.planet_elem_5)}
      >
        <img src={elem5Src} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: -40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1 }}
        className={cn(styles.planet_elem, styles.planet_elem_6)}
      >
        <img src={elem6Src} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.5 }}
        className={cn(styles.planet_elem, styles.planet_elem_7)}
      >
        <img src={elem5Src} alt="" />
      </motion.div>
    </div>
  );
};
