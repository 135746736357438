import { useEffect, useMemo, useState } from 'react';
import { Game } from '../../pages/Game';
import { Home } from '../../pages/Home';
import { Final } from '../../pages/Final';
import styles from './App.module.scss';
import { PageContext } from './PageContext';
import { Loader } from '../Loader';

export const App = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState('home'); // home / game / final

  const renderCurrentPage = useMemo(() => {
    switch (page) {
      case 'home':
        return <Home />;
      case 'game':
        return <Game />;
      case 'final':
        return <Final />;
      default:
        return <div className="error_render">Ошибка состояния приложения!</div>;
    }
  }, [page]);

  let timerId: ReturnType<typeof setTimeout>;

  useEffect(() => {
    timerId = setTimeout(
      () => {
        setShowLoader(false);
      },
      process.env.NODE_ENV === 'development' ? 10 : 1200,
    );

    return () => clearTimeout(timerId);
  }, []);

  return (
    <PageContext.Provider value={{ page, setPage }}>
      {showLoader ? (
        <Loader />
      ) : (
        <div className={styles.wrapper}>{renderCurrentPage}</div>
      )}
    </PageContext.Provider>
  );
};
