import animateScrollTo from 'animated-scroll-to';

export const scrollToElement = (element: HTMLDivElement | null) => {
  if (element) {
    animateScrollTo(element, {
      cancelOnUserAction: false,
      easing: (t) => {
        return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
      },
      minDuration: 500,
      // maxDuration: 2000,
      speed: 1500,
    });
  }
};
