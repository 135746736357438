import { usePageContext } from '@/src/components/App/PageContext';
import styles from './Home.module.scss';
import { noop } from '@/src/utils';
import { Footer } from '@/src/components/Footer';
import { Logo } from '@/src/components/Logo';
import { HomePlanet } from './components/HomePlanet';
import { Button } from '@/src/components/Button';
import { AnimateFade } from '@/src/components/AnimateFade';
import { useMediaQuery } from 'usehooks-ts';
import { sendEventToCounters } from '@/src/counterEvents';

export const Home = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const handleClickToGame = () => {
    changePage('game');
    sendEventToCounters({ action: 'landing', label: 'ahead' });
  };

  const isMediaQueryLess800 = useMediaQuery('(max-width: 800px)');

  const Vote = () => {
    return (
      <AnimateFade delay={1} className={styles.home_vote}>
        <div className={styles.home_vote_content}>
          <p className={styles.home_vote_title}>
            <b>Уже знаком с командой Самолет?</b>
          </p>
          <p>Поддержи нас в рейтинге работодателей России</p>
        </div>
        <Button
          type="link"
          href="https://rating.hh.ru/poll/?utm_source=samolet_hh&utm_medium=referral&utm_campaign=special_project_start"
          text="Голосовать за Самолет"
          size="small"
          onClick={() =>
            sendEventToCounters({ action: 'landing', label: 'vote' })
          }
        />
      </AnimateFade>
    );
  };

  return (
    <div className={styles.home}>
      <div className={styles.home_row}>
        <div className={styles.home_content}>
          <AnimateFade delay={0.2}>
            <Logo />
          </AnimateFade>

          <div className={styles.home_intro}>
            <AnimateFade delay={0.4}>
              <h1 className={styles.home_title}>
                Дело в <span>нас!</span>
              </h1>
            </AnimateFade>
            <AnimateFade delay={0.6}>
              <p className={styles.home_intro_text}>
                Выбери, какие качества тебе ближе, а&#160;мы&#160;расскажем, как
                в&#160;команде Самолет ты&#160;можешь вращать планету, создавая
                важное и&#160;масштабное!
              </p>
            </AnimateFade>
            <AnimateFade delay={0.8}>
              <Button onClick={handleClickToGame} text="Вперед" color="blue" />
            </AnimateFade>
          </div>

          {!isMediaQueryLess800 && <Vote />}
        </div>

        <HomePlanet />

        {isMediaQueryLess800 && <Vote />}
      </div>

      <AnimateFade delay={1.2}>
        <Footer />
      </AnimateFade>
    </div>
  );
};
