import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  currentAnswers: string[];
}

const initialState: IState = {
  currentAnswers: [],
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    addAnswer(state, action: PayloadAction<string>) {
      state.currentAnswers = [...state.currentAnswers, action.payload];
    },
  },
});

export const { addAnswer } = gameSlice.actions;

export default gameSlice;
