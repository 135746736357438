/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './Game.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Footer } from '@/src/components/Footer';
import { Logo } from '@/src/components/Logo';
import { Question } from './components/Question';
import { GAME_DATA } from './data';
import { GameTitle } from './components/GameTitle';
import { GameNavigation } from './components/GameNavigation';
import { GameSelector } from './components/GameSelector';
import { GameContext } from './GameContext';
import { scrollToElement } from '@/src/utils';
import { AnimateFade } from '@/src/components/AnimateFade';

export const Game = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<-1 | 1 | null>(null);
  const currentQuestion = useMemo(() => GAME_DATA[currentStep], [currentStep]);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (currentStep > 0) {
      scrollToElement(wrapperRef.current);
    }
  }, [currentStep]);

  return (
    <div className={styles.game} ref={wrapperRef}>
      <div className={styles.game_content}>
        <AnimateFade>
          <Logo />
        </AnimateFade>

        <GameContext.Provider
          value={{
            currentStep,
            setCurrentStep,
            selectedAnswer,
            setSelectedAnswer,
          }}
        >
          <div className={styles.game_wrapper}>
            <AnimateFade delay={0.2}>
              <GameTitle />
            </AnimateFade>

            <AnimateFade delay={0.4}>
              <GameNavigation current={currentStep} total={GAME_DATA.length} />
            </AnimateFade>

            <Question question={currentQuestion.answers} />

            <GameSelector />
          </div>
        </GameContext.Provider>
      </div>
      <AnimateFade delay={0.6}>
        <Footer />
      </AnimateFade>
    </div>
  );
};
