export const RESULT = {
  'dreamy,humane,command,logical,bold':
    'Ты видишь необычное в обыденном, заботишься о других, умеешь играть в команде, ценишь правду, готов к неожиданным поворотам',

  'dreamy,humane,command,logical,careful':
    'Ты умеешь вдохновлять, проявляешь эмпатию и понимание, работаешь на общий результат, умело структурируешь идеи, доверяешь своей интуиции',

  'dreamy,humane,command,adventurous,bold':
    'Ты стремишься к идеалам, ценишь искренность, поддерживаешь дух сотрудничества, готов к любым неожиданностям, не боишься рисковать и плыть против течения',

  'dreamy,humane,command,adventurous,careful':
    'Ты видишь необычное в обыденном, заботишься о других, умеешь играть в команде, не боишься пробовать новое, всегда взвешиваешь «за» и «против»',

  'dreamy,humane,independent,logical,bold':
    'Ты умеешь вдохновлять, проявляешь эмпатию и понимание, ценишь независимость и свободу, умело структурируешь идеи, стремишься к новым вызовам',

  'dreamy,humane,independent,logical,careful':
    'Ты стремишься к идеалам, ценишь искренность, веришь в свои силы, умеешь анализировать информацию, принимаешь обдуманные решения',

  'dreamy,humane,independent,adventurous,bold':
    'Ты видишь необычное в обыденном, заботишься о других, берешь ответственность за свои решения, не боишься пробовать новое, готов к неожиданным поворотам',

  'dreamy,humane,independent,adventurous,careful':
    'Ты умеешь вдохновлять, проявляешь эмпатию и понимание, ценишь независимость и свободу, любишь исследовать неизведанное, доверяешь своей интуиции',

  'dreamy,technological,command,logical,bold':
    'Ты стремишься к идеалам, легко адаптируешься к новому, поддерживаешь дух сотрудничества, умеешь анализировать информацию, не боишься рисковать и плыть против течения',

  'dreamy,technological,command,logical,careful':
    'Ты видишь необычное в обыденном, следишь за трендами в технологиях, умеешь играть в команде, ценишь правду, всегда взвешиваешь «за» и «против»',

  'dreamy,technological,command,adventurous,bold':
    'Ты умеешь вдохновлять, видишь будущее в инновациях, работаешь на общий результат, любишь исследовать неизведанное, стремишься к новым вызовам',

  'dreamy,technological,command,adventurous,careful':
    'Ты стремишься к идеалам, легко адаптируешься к новому, поддерживаешь дух сотрудничества, готов к любым неожиданностям, принимаешь обдуманные решения',

  'dreamy,technological,independent,logical,bold':
    'Ты видишь необычное в обыденном, следишь за трендами в технологиях, берешь ответственность за свои решения, ценишь правду, готов к неожиданным поворотам',

  'dreamy,technological,independent,logical,careful':
    'Ты умеешь вдохновлять, видишь будущее в инновациях, ценишь независимость и свободу, умело структурируешь идеи, доверяешь своей интуиции',

  'dreamy,technological,independent,adventurous,bold':
    'Ты стремишься к идеалам, легко адаптируешься к новому, веришь в свои силы, готов к любым неожиданностям, не боишься рисковать и плыть против течения',

  'dreamy,technological,independent,adventurous,careful':
    'Ты видишь необычное в обыденном, следишь за трендами в технологиях, берешь ответственность за свои решения, не боишься пробовать новое, всегда взвешиваешь «за» и «против»',

  'pragmatic,humane,command,logical,bold':
    'Ты находишь простые решения сложных задач, проявляешь эмпатию и понимание, работаешь на общий результат, умело структурируешь идеи, стремишься к новым вызовам',

  'pragmatic,humane,command,logical,careful':
    'Ты сосредоточен на практическом результате, ценишь искренность, поддерживаешь дух сотрудничества, умеешь анализировать информацию, принимаешь обдуманные решения',

  'pragmatic,humane,command,adventurous,bold':
    'Ты сосредоточен на эффективности, заботишься о других, умеешь играть в команде, не боишься пробовать новое, готов к неожиданным поворотам',

  'pragmatic,humane,command,adventurous,careful':
    'Ты находишь простые решения сложных задач, проявляешь эмпатию и понимание, работаешь на общий результат, любишь исследовать неизведанное, доверяешь своей интуиции',

  'pragmatic,humane,independent,logical,bold':
    'Ты сосредоточен на практическом результате, ценишь искренность, веришь в свои силы, умеешь анализировать информацию, не боишься рисковать и плыть против течения',

  'pragmatic,humane,independent,logical,careful':
    'Ты сосредоточен на эффективности, заботишься о других, берешь ответственность за свои решения, ценишь правду, всегда взвешиваешь «за» и «против»',

  'pragmatic,humane,independent,adventurous,bold':
    'Ты находишь простые решения сложных задач, проявляешь эмпатию и понимание, ценишь независимость и свободу, любишь исследовать неизведанное, стремишься к новым вызовам',

  'pragmatic,humane,independent,adventurous,careful':
    'Ты сосредоточен на практическом результате, ценишь искренность, веришь в свои силы, готов к любым неожиданностям, принимаешь обдуманные решения',

  'pragmatic,technological,command,logical,bold':
    'Ты сосредоточен на эффективности, следишь за трендами в технологиях, умеешь играть в команде, ценишь правду, готов к неожиданным поворотам',

  'pragmatic,technological,command,logical,careful':
    'Ты находишь простые решения сложных задач, видишь будущее в инновациях, работаешь на общий результат, умело структурируешь идеи, доверяешь своей интуиции',

  'pragmatic,technological,command,adventurous,bold':
    'Ты сосредоточен на практическом результате, легко адаптируешься к новому, поддерживаешь дух сотрудничества, готов к любым неожиданностям, не боишься рисковать и плыть против течения',

  'pragmatic,technological,command,adventurous,careful':
    'Ты сосредоточен на эффективности, следишь за трендами в технологиях, умеешь играть в команде, не боишься пробовать новое, всегда взвешиваешь «за» и «против»',

  'pragmatic,technological,independent,logical,bold':
    'Ты находишь простые решения сложных задач, видишь будущее в инновациях, ценишь независимость и свободу, умело структурируешь идеи, стремишься к новым вызовам',

  'pragmatic,technological,independent,logical,careful':
    'Ты сосредоточен на практическом результате, легко адаптируешься к новому, веришь в свои силы, умеешь анализировать информацию, принимаешь обдуманные решения',

  'pragmatic,technological,independent,adventurous,bold':
    'Ты сосредоточен на эффективности, следишь за трендами в технологиях, берешь ответственность за свои решения, не боишься пробовать новое, готов к неожиданным поворотам',

  'pragmatic,technological,independent,adventurous,careful':
    'Ты находишь простые решения сложных задач, видишь будущее в инновациях, ценишь независимость и свободу, любишь исследовать неизведанное, доверяешь своей интуиции',
};
