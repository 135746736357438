import questionImage_1_left from './images/question1_left.svg';
import questionImage_1_right from './images/question1_right.svg';
import questionImage_1_mobile_left from './images/mobile_question1_left.svg';
import questionImage_1_mobile_right from './images/mobile_question1_right.svg';

import questionImage_2_left from './images/question2_left.svg';
import questionImage_2_right from './images/question2_right.svg';
import questionImage_2_mobile_left from './images/mobile_question2_left.svg';
import questionImage_2_mobile_right from './images/mobile_question2_right.svg';

import questionImage_3_left from './images/question3_left.svg';
import questionImage_3_right from './images/question3_right.svg';
import questionImage_3_mobile_left from './images/mobile_question3_left.svg';
import questionImage_3_mobile_right from './images/mobile_question3_right.svg';

import questionImage_4_left from './images/question4_left.svg';
import questionImage_4_right from './images/question4_right.svg';
import questionImage_4_mobile_left from './images/mobile_question4_left.svg';
import questionImage_4_mobile_right from './images/mobile_question4_right.svg';

import questionImage_5_left from './images/question5_left.svg';
import questionImage_5_right from './images/question5_right.svg';
import questionImage_5_mobile_left from './images/mobile_question5_left.svg';
import questionImage_5_mobile_right from './images/mobile_question5_right.svg';

import { TAnswer } from './types';

type TGameDataAnswer = {
  answers: TAnswer[];
};

type TGameData = TGameDataAnswer[];

export const GAME_DATA: TGameData = [
  {
    answers: [
      {
        name: 'Мечтательный',
        id: 'dreamy',
        image: questionImage_1_left,
        image_mobile: questionImage_1_mobile_left,
        description: `
          <p>Ты видишь возможности там, где другие видят преграды.</p>
          <p>Такие мечтатели запустили для коллег проект «Самолетные», где рассказывают, как ценности компании помогают нам создавать ценные продукты для миллионов людей.</p>
        `,
      },
      {
        name: 'Прагматичный',
        id: 'pragmatic',
        image: questionImage_1_right,
        image_mobile: questionImage_1_mobile_right,
        description: `
          <p>Ты умеешь находить практичные решения в самых неординарных ситуациях.</p>
          <p>Такие прагматики в составе команды Самолет смогли за 3 года увеличить объем прибыли в 16 раз.</p>        
        `,
      },
    ],
  },

  {
    answers: [
      {
        name: 'Человечный',
        id: 'humane',
        image: questionImage_2_left,
        image_mobile: questionImage_2_mobile_left,
        description: `
          <p>Ты ставишь на первое место эмоции и потребности людей.</p>
          <p>Такие гуманисты в команде Самолета развивают новый формат мероприятий для сотрудников – «Talk в Толк», где мы обсуждаем нерабочие темы и создаем доверительную среду общения.</p>
        `,
      },
      {
        name: 'Технологичный',
        id: 'technological',
        image: questionImage_2_right,
        image_mobile: questionImage_2_mobile_right,
        description: `
          <p>Ты используешь инновации для упрощения жизни и повышения эффективности.</p>
          <p>Команда инженеров и разработчиков Самолета создала первый финтех-проект в девелопменте и построила цифровой банк с нуля.</p>
        `,
      },
    ],
  },

  {
    answers: [
      {
        name: 'Командный',
        id: 'command',
        image: questionImage_3_left,
        image_mobile: questionImage_3_mobile_left,
        description: `
          <p>Ты веришь в силу сотрудничества и достижения общих целей вместе.</p>
          <p>Объединившись вокруг общей мечты – вращать планету – мы в Самолете собрали команду из 8 000+ сотрудников и уже вывели компанию на 1 место по величине среди девелоперов России и Европы.</p>
        `,
      },
      {
        name: 'Самостоятельный',
        id: 'independent',
        image: questionImage_3_right,
        image_mobile: questionImage_3_mobile_right,
        description: `
          <p>Ты способен принимать решения и не боишься брать на себя ответственность.</p>
          <p>Для тех, кто ценит свободу быть собой, в Самолете мы создали систему гибких графиков, чтобы коллеги из 355 городов могли самостоятельно регулировать баланс работы и отдыха.</p>
        `,
      },
    ],
  },

  {
    answers: [
      {
        name: 'Логичный',
        id: 'logical',
        image: questionImage_4_left,
        image_mobile: questionImage_4_mobile_left,
        description: `
          <p>Ты анализируешь информацию и делаешь выводы на основе фактов.</p>
          <p>Новаторы в Самолете создали логичную модель оценки эффективности: она позволяет всем, кто принимает умные решения, получить пересмотр заработной платы без привязки к ежегодным ревью.</p>
        `,
      },
      {
        name: 'Авантюрный',
        id: 'adventurous',
        image: questionImage_4_right,
        image_mobile: questionImage_4_mobile_right,
        description: `
          <p>Ты готов исследовать новое и принимать вызовы, даже если это требует риска.</p>
          <p>Наши визионеры внедрили «Карту опций квартир», которая внесла значимые изменения в оценку качества выполненных подрядчиками работ: эффективность приёмки выросла в ХХ раз.</p>
        `,
      },
    ],
  },

  {
    answers: [
      {
        name: 'Дерзкий',
        id: 'bold',
        image: questionImage_5_left,
        image_mobile: questionImage_5_mobile_left,
        description: `
          <p>Ты не боишься выходить за рамки привычного и не признаешь статус кво.</p>
          <p>Отважные гении в Самолете смело переписывают правила: мы создали цифровой жилой комплекс «Ценностный», в котором каждый дом отображает ценности Самолета, объединяющие сотрудников и жителей наших ЖК.</p>
        `,
      },
      {
        name: 'Осторожный',
        id: 'careful',
        image: questionImage_5_right,
        image_mobile: questionImage_5_mobile_right,
        description: `
          <p>Ты всегда взвешиваешь риски и принимаешь обдуманные решения.</p>
          <p>Оптимизаторы в Самолете оценивают любую идею в балансе ценности для клиента и финансового результата: мы придумали программу «Школа рабочих» – приложение для повышения квалификации подрядных рабочих на стройке.</p>
        `,
      },
    ],
  },
];

type TResult = Record<string, string[]>;

export const GAME_RESULTS: TResult[] = [
  {
    dreamy: [
      'видишь необычное в обыденном',
      'умеешь вдохновлять',
      'стремишься к идеалам',
    ],
    pragmatic: [
      'сосредоточен на эффективности',
      'находишь простые решения сложных задач',
      'сосредоточен на практическом результате',
    ],
  },

  {
    humane: [
      'заботишься о других',
      'проявляешь эмпатию и понимание',
      'ценишь искренность',
    ],
    technological: [
      'следишь за трендами в технологиях',
      'видишь будущее в инновациях',
      'легко адаптируешься к новому',
    ],
  },

  {
    command: [
      'умеешь играть в команде',
      'работаешь на общий результат',
      'поддерживаешь дух сотрудничества',
    ],
    independent: [
      'берешь ответственность за свои решения',
      'ценишь независимость и свободу',
      'веришь в свои силы',
    ],
  },

  {
    logical: [
      'ценишь правду',
      'умело структурируешь идеи',
      'умеешь анализировать информацию',
    ],
    adventurous: [
      'не боишься пробовать новое',
      'любишь исследовать неизведанное',
      'готов к любым неожиданностям',
    ],
  },

  {
    bold: [
      'готов к неожиданным поворотам',
      'стремишься к новым вызовам',
      'не боишься рисковать и плыть против течения',
    ],
    careful: [
      'всегда взвешиваешь «за» и «против»',
      'доверяешь своей интуиции',
      'принимаешь обдуманные решения',
    ],
  },
];
